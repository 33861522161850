#univerities_cards .article-card {
    /* width: 350px; */
    height: 220px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 300ms;
  }
  
  #univerities_cards .article-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  
  #univerities_cards .article-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  #univerities_cards .article-card .content {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    padding: 30px 20px 20px 20px;
    height: auto;
    bottom: 0;
    opacity: 1;
    /* top: 0; */
    z-index: 1;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  }
  
  #univerities_cards .article-card .date,
  #univerities_cards .article-card .title {
    margin: 0;
  }
  
  #univerities_cards .article-card .date {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 4px;
  }
  
  #univerities_cards .article-card .title {
    font-size: 17px;
    color: #fff;
  }
  