#subscribe_sec {
  margin-top: 50px;
  margin-bottom: 50px;
}
#subscribe {
  height: 350px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #edf3f5;
  overflow: hidden;
  box-shadow: 0px 14px 19px rgba(221, 229, 236, 0.42);
  border: 1px solid #edf3f5;
  /* display: grid;
  grid-template-columns: 2fr 1fr; */
  background-image: url("/public/assets/images/cover/subs-image-removebg.png");
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}
#subscribe-text {
  max-width: 60%;
  padding: 40px;
  display: flex;
  justify-content: center;
  /* align-items:center; */
  flex-direction: column;
  gap: 20px;
}
#subscribe-text form {
  display: flex;
  gap: 20px;
}

#subscribe-text input {
  border-radius: 10px;
  width: calc(100% - 200px);
  border: none;
  outline: none;
  padding: 20px;
}

#subscribe-text button {
  padding: 20px 0;
  height: 70px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  background-color: #00cc99;
  color: white;
  border: 1px solid white;
  width: 180px;
  cursor: pointer;
}
#subscribe-text p {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.72px;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #0f172a;
}
@media screen and (max-width: 768px) {
  #subscribe {
    background-image: none;
  }
  #subscribe-text {
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  #subscribe-text form {
    display: flex;
    flex-direction: column;
  }
  #subscribe-text form input,
  #subscribe-text form button {
    width: 100%;
  }
}
