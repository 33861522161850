@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.container {
  max-width: 1208px;
  margin: 0 auto;
  padding: 0 25px;
}
div img {
  width: 100%;
}
main {
  padding-top: 110px;
  /* height: 200vh; */
}
section{
  max-width: 1600px;
  margin: 0 auto;
  
}