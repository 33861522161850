#contact_form_sec {
  background-color: #edf3f5;
  padding-top: 50px;
  padding-bottom: 50px;
}

#contact_form {
  border-radius: 20px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0px 14px 19px rgba(221, 229, 236, 0.42);
  border: 1px solid #edf3f5;
  display: flex;
  justify-content: space-between;
}
#contact_form > div {
  padding: 40px;
}
#contact_form p {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.72px;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #0f172a;
}
#contact_form .form_group {
  height: 60px;
  border: 1px solid #d0d6d8;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  flex-wrap: nowrap;
  position: relative;
}
#contact_form .form_group label input {
  outline: none;
  border: none;
  font-size: 14px;
}
#contact_form .form_group label,
#contact_form .form_group label input {
  width: 100%;
}
#contact_form .form_group i {
  color: gray;
}
#contact_form iframe {
  width: 500px;
}
#contact_form .form_group label {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 100%;
}
#contact_form form {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}
.form_group.sel_op > div {
  width: 100%;
}
.form_group.sel_op div {
  border: none !important;
  outline: none !important;
  box-shadow: none;
  font-size: 14px;
}
.css-1nmdiq5-menu {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1) !important;
}
.form_group.textarea {
  font-size: 14px;
  width: 100% !important;
  grid-column-start: 1;
  grid-column-end: 3;
  height: 150px !important;
  padding: 20px !important;
  gap: 10px;
}
.form_group.textarea textarea {
  width: 100%;
  height: 100%;
  outline: none !important;
  resize: none;
  border: none;
}
.form_group.textarea label{
  height: 100%;
  align-items: flex-start !important;

}
#contact_form button {
  padding: 20px 0;
  height: 70px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  background-color: #00cc99;
  color: white;
  border: 1px solid white;
  width: 180px;
  cursor: pointer;
}
#contact_form .form_group span{
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 12px;
  color: red;
  left: 20px;
}
.form_group.error {
  border: 1px solid red!important;
}
@media screen and (max-width: 1024px) {
  #contact_form {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  #contact_form iframe {
    width: 100%;
    height: 300px;
  }
}
@media screen and (max-width: 600px) {
  .form_group.textarea {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
