header {
  height: 110px;
  width: 100%;
  position: fixed;
  background-color: white;
  /* background-color: transparent; */
  top: 0;
  left: 0;
  z-index: 99999999;
  border-bottom: 1px solid gainsboro;
}
header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
#h-logo {
  width: 110px;
}
header nav ul {
  display: flex;
  align-items: center;
  gap: 40px;
  transition: 0.4s;
  background-color: white;
  height: 100%;
}
header nav ul li a {
  color: #0f2f64;
  font-size: 16px;
  font-weight: 500;
}
header nav ul li a.active {
  color: #1e5dbc;
  font-size: 16px;
}
#menu {
  width: 30px;
  display: none;
}

header.scrolled {
  position: fixed;
  animation: headerAnimation 0.4s;
}

@keyframes headerAnimation {
  0% {
    top: -110px;
  }
  100% {
    top: 0;
  }
}
@media screen and (max-width: 820px) {
  #menu {
    display: block;
  }
  header nav {
    overflow: hidden;
    position: absolute;
    top: 110px; /* Position the nav below the header */
    right: 0;
    left: 0; /* Ensure it spans the full width */
    z-index: 2;
    gap: 10px;
    min-width: 100%;
    transition: transform 0.4s, opacity 0.4s;
    background-color: white; /* Ensure background color for visibility */
  }
  header nav.menuShow {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto; /* Enable interaction */
  }
  header nav:not(.menuShow) {
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none; /* Disable interaction when hidden */
  }
  header nav ul {
    flex-direction: column;
    overflow-y: auto;
    padding: 25px;
    max-height: calc(100vh - 110px); /* Adjust to vh */
  }
}
.switch {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.switch .current {
  height: 42px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-size: 1rem;
  text-align: center;
  line-height: 42px;
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s ease-out;
  border-radius: 3px;
  padding: 0 0.2em 0 0.7em;
  
  background-color: rgba(0, 0, 0, 0.1);
}
.switch .current i{
  padding-left: 5px;
}

/* .switch .current:hover {
  background-color: rgba(0, 0, 0, 0.1);
} */

.switch .options {
  position: absolute;
  right: 0;
  top: 0.6em;
  z-index: 0;
  opacity: 0;
  transition: all 0.36s ease-out;
  display: none;
  overflow: hidden;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.switch.show-options .options {
  display: block;
  z-index: 5;
}

.switch.anim-options .options {
  opacity: 1;
}

.switch.show-shadow .options {
  box-shadow: 0 2px 12px -4px rgba(0, 0, 0, 0.4);
}

.switch .options-list {
  color: var(--color-dark);
  margin: 0;
  padding: 0.4rem 0.8em;
  position: relative;
  z-index: 5;
}

.switch .options-list li {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 0.75em;
  border-bottom: 1px solid #e1e4e6;
  transform: translateX(10px);
  transition: all 0.24s ease-out;
  opacity: 0;
  font-size: 1rem;
  line-height: 1.3;
  white-space: nowrap;
}

.switch .options-list li:last-child {
  border-bottom: none;
}

.switch .options-list li:nth-child(1) {
  transition-delay: 0.1s;
}

.switch .options-list li:nth-child(2) {
  transition-delay: 0.15s;
}

.switch .options-list li:nth-child(3) {
  transition-delay: 0.2s;
}

.switch .options-list li:nth-child(4) {
  transition-delay: 0.25s;
}

.switch .options-list li:nth-child(5) {
  transition-delay: 0.3s;
}

.switch .options-list li.selected {
  color: #1e5dbc;
}

.switch.anim-options .options-list li {
  transform: translateX(0);
  opacity: 1;
}

.switch #trans-circle {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -80px;
  right: -80px;
  padding: 0;
  margin: 0;
  border: none;
  z-index: 1;
  transition: all 0.4s ease-out;
  transform: scale(0.5);
  overflow: hidden;
  background-color: white;
}

.switch.anim-options #trans-circle {
  transform: scale(9);
}
