#news_sec {
  background-color: #edf3f5;
  padding: 50px 0;
}
#news_sec #education_steps_text {
  text-align: center;
}
#news_sec #education_steps_text h2 span {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  /* line-height: 1.2; */
  letter-spacing: 0px;
  display: block;
  margin-top: 15px;
}
#news_cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

