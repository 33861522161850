#chooseCountry {
  margin-top: 50px;
}
#chooseCountry ul {
  height: auto;
  /* background-color: #dee2e6; */
  background-color: #f4f4f4;
  /* display: flex; */
  width: 100%;
  /* border: 1px solid black; */
  border: 0;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
}
#chooseCountry ul li {
  width: 100%;
  /* width: calc((100% - 50px) / 6); */
  /* border: 1px solid black; */
  height: 40px;
  color: black;
  text-align: center;
  border: 0;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#chooseCountry ul li:not(.active, .active + li):nth-child(n + 2):after {
  position: absolute;
  content: "";
  top: 0;
  left: -5px;
  width: 2px;
  height: 100%;
  background-color: #dee2e6;
}
#chooseCountry ul li.active {
  border-radius: 5px;
  background-color: white;
  border: none;
}
#univerities_cards {
  /* display: flex; */
  /* flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-top: 30px;
  gap: 30px;
}
.university_card {
  /* width: 366px; */
  aspect-ratio: 4/3;
  background-color: #f4f4f4;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* gap: 15px; */
}
.university_card_image {
  width: 100%;
  height: calc(100% - 83px);
}
.university_card_image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgb(179, 153, 153);
  object-fit: cover;
}
.university_card_text {
  border-radius: 5px;
  height: 30px;
}
.university_card_text span {
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  color: gray;
  margin-top: 7px;
  display: block;
  height: 46px;
}
.university_card_text h4 {
  font-size: 24px;
  font-weight: 500;
  color: black;
}
@media screen and (max-width: 1024px) {
  #chooseCountry ul {
    gap: 10px;
    padding: 7px;
  }
  #chooseCountry ul li {
    font-size: 15px;
  }
}
@media screen and (max-width: 768px) {
  #chooseCountry ul {
    flex-wrap: wrap;
    /* width: calc((100% - 20px) / 3); */
  }
  #chooseCountry ul li {
    width: calc((100% - 10px) / 2);
  }
  #chooseCountry ul li:nth-child(2n+1)::after {
    background-color: transparent !important;
  }
}
