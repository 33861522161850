#footer {
    background: #f7f7f7;
    padding: 3rem;
    /* padding-top: 5rem; */
    padding-top: 7rem;
    padding-bottom: 80px;
}

#footer2 {
    background: #f7f7f7;
    padding: 3rem;
    margin-top: 0px;
    /* padding-top: 5rem; */
    padding-top: 7rem;
    padding-bottom: 80px;
}

.logo-footer {
    max-width: 300px;
}

.social-links {
    /* display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; */

}

.social-links h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
}

.social-links img {
    padding-bottom: 25px;
}

.social-icons {
    /* display: flex;
      gap: 3rem; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #777777;
}

.social-icons a {
    /* font-size: 18px; */
    /* background: #ffffff; */
    /* box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
      padding: 0.4rem 1rem 0.4rem 1rem;
      border-radius: 3px;
    color: #82074a; */
    /* margin-right: 18px; */
    color: #777777;
}

.social-icons a:hover {
    color: #000;
}

.social-icons a i {
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
    padding: 0.4rem 1rem 0.4rem 1rem;
    border-radius: 3px;
    color: #82074a;
    font-size: 16px;
    margin-right: 12px;
}

li {
    list-style: none;
}

.useful-link h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
}

.useful-link img {
    padding-bottom: 15px;
}

.use-links {
    line-height: 32px;
}

.use-links li i {
    font-size: 14px;
    padding-right: 8px;
    color: #898989;
}

.use-links li a {
    color: #303030;
    font-size: 15px;
    font-weight: 500;
    color: #777777;
}

.use-links li a:hover {
    color: #000;
}

.address h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
}

.address img {
    padding-bottom: 15px;
}

.address-links li a {
    color: #303030;
    font-size: 15px;
    font-weight: 500;
    color: #777777;

}

.address-links li i {
    font-size: 16px;
    padding-right: 8px;
    color: #82074a;

}

.address-links li i:nth-child(1) {
    padding-top: 9px;
}

.address-links .address1 {
    font-weight: 500;
    font-size: 15px;
    display: flex;
}

.address-links {
    line-height: 32px;
    color: #777777;
}

.copy-right-sec {
    padding: 1.8rem;
    background: #82074a;
    color: #fff;
    text-align: center;
}

.copy-right-sec a {
    color: #fcd462;
    font-weight: 500;
}

a {
    text-decoration: none;
}

.footer-distributed {
    background: #666;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 55px 50px;
}

footer .container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

footer .container>div {
    width: calc((100% - 30px)/4);
}

footer .container>div:nth-child(1) img {
    /* width: 100%; */
    width: 80%;
}

footer .container>div:nth-child(1) {
    display: flex;
    align-items: center;
}

@media screen and (max-width:1024px) {
    footer .container>div {
        width: calc((100% - 10px)/2);

    }
}

@media screen and (max-width:768px) {
    footer .container>div {
        width: 100%;
    }

    footer .container>div h2 {
        text-align: center;
        padding-top: 20px;
    }

    footer .container>div:nth-child(1) a {
        display: flex;
        justify-content: center;
    }

    footer .container>div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #footer {
        padding-left: 0;
        padding-right: 0;
    }
}
footer a{
display: flex;
align-items: center;
}
footer i {
    color: #0356B4 !important;
    width: 50px;
    display: flex!important;
    align-items: center;
    justify-content: center;
}