#education_steps_sec {
  background-image: url("/public/assets/images/cover/education_steps-removebg.png");
  min-height: 85vh;
  max-height: 700px;
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  background-color: #edf3f5;
}
#education_steps_sec .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 85vh;
  max-height: 700px;
  padding-top: 50px;
  padding-bottom: 50px;
  gap: 20px;
}

#education_steps_text h2,
#education_steps_text h2 span {
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -1.5px;
  font-family: "Plus Jakarta Sans", sans-serif;
}
#education_steps_text h2 span {
  color: gray;
}
#education_steps {
  max-width: 750px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}
.education_step {
  /* height: 150px; */
  background-color: white;
  box-shadow: 0px 30px 45px rgba(122, 146, 168, 0.08);
  border-radius: 15px;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.education_step_number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fe6c3f;
  color: white;
}
.education_step::before {
  position: absolute;
  width: 45px;
  height: 105px;
  border-radius: 30px;
  background-color: #f4f8fa;
  content: "";
  top: 25px;
  left: 50px;
  z-index: -1;
  border-top-right-radius: 0;
  transform: rotate(-45deg) translate(-50%,-50%);
}
.education_step_content {
  width: calc(100% - 80px);
}
.education_step_content h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.education_step_content p {
  margin-top: 10px;
  color: #58595e;
  font-size: 16px;
  line-height: 24px;
}
.blue_color {
  background-color: #1496f8;
}
.green_color {
  background-color: #00cc99;
}
.yellow_color {
  background-color: #ffbd0f;
}
@media (max-width: 1200px) and (min-width: 950px) {
  #education_steps_sec {
    background-size: 700px;
  }
  #education_steps {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
  }
  #education_steps > div {
    width: 100%;
  }
  #education_steps > div:nth-child(1) {
    grid-column: span 1;
    grid-row: 1;
  }
  #education_steps > div:nth-child(2) {
    grid-row: 2;
  }
  #education_steps > div:nth-child(3),
  #education_steps > div:nth-child(4) {
    grid-row: 3;
  }
}
@media (max-width: 950px) {
  #education_steps {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: auto;
  }
  #education_steps_sec {
    height: 900px;
    background-size: 500px;
    max-height: max-content;
  }
}
@media (max-width: 768px) {
  #education_steps_sec,
  #education_steps_sec .container {
    background-image: none;
    height: fit-content;
    max-height: none;
  }
}
