#first_section_ {
  /* background-image: url("/public/assets/images/cover/section-head.jpg"); */
  background-color: rgb(192, 192, 192);
  background-repeat: no-repeat;
  height: 150px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.u#first_section_::after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  background: linear-gradient(30deg, #0056b9, rgba(255, 215, 0, 0.39));
}
#first_section_ .container {
  /* height: 440px; */
  height: 100%;
  display: flex;
  align-items: center;
}
#first_section_text {
  /* width: 630px; */
  max-width: 100%;
}
#first_section_text h1 {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: -1px;
  line-height: 86px;
  color: #0f172a;
}
#contries_div {
  width: max-content;
  height: 42px;
  background-color: white;
  padding: 10px 20px;
}
.links_for span {
  margin: 0 4px;
  font-weight: 700;
  color: black;
  font-size: 14px;
}
#contries_div p {
  display: inline-block;
  margin-left: 1px;
  font-weight: 700;
  color: rgba(33, 37, 41, 0.75);
  font-size: 14px;
}
#main_second_section {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 120px;
  gap: 50px;
}
#text_foto_second_sec h2,
#specialities_section h2 {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -1px;
  color: #0f172a;
  margin: 25px 0 15px 0 ;
}
#text_foto_second_sec h4 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.72px;
  line-height: 1.3;
  color: #0f172a;
}
#text_foto_second_sec p {
  color: #787b84;
  font-family: "Plus Jakarta Sans", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.2px;
  margin-top: 20px;
}
#img_second_sec {
  margin-top: 60px;
  margin-bottom: 50px;
}
#img_second_sec img {
  width: 100%;
}
.boxes_choose {
  width: 45%;
  height: 109px;
  border: 1px solid #edf3f5;
  margin: 15px;
  border-radius: 10px;
  padding: 30px 25px;
  position: relative;
  transition: 0.3s;
  display: flex;
}
.boxes_choose::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 47px;
  background-color: #fe6c3f;
  transform: translateY(-50%);
}
#flx_second_sec {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.blue::before {
  background-color: #1496f8;
}
.green::before {
  background-color: #00cc99;
}
.yellow::before {
  background-color: #ffbd0f;
}
.svg_icon {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background-color: rgba(254, 108, 63, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.boxes_choose h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.6px;
  margin-bottom: 0;
}
.text_center_second_sec {
  display: flex;
  align-items: center;
}
#foto_and_text_universitets {
  margin-top: 25px;
  display: flex;
  gap: 60px;
}
#stars_universitets {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
#stars_universitets span {
  font-size: 20px;
  color: #787b84;
  font-weight: 500;
  padding-left: 5px;
}
.aside_second_sec {
  background-color: #edf3f5;
  padding: 30px 40px;
  width: 100%;
  border-radius: 20px;
}
.country_item {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.country_item li {
  width: 100%;
  height: 68px;
}
.country_item a {
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #787b84;
  background-color: white;
  padding: 11px 20px;
  border-radius: 10px;
  font-weight: 500;
}
.img_country img {
  width: 34px;
  height: 34px;
  margin-top: 10px;
}
#pluss_country {
  color: #0f172a;
  font-size: 14px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #edf3f5;
}
.margin {
  margin-top: 50px;
}
#main_second_section section {
  width: calc(100% - 400px);
}
#main_second_section aside {
  width: 350px;
}
.aside_second_sec h3 {
  font-size: 24px;
  color: #0f172a;
  margin-bottom: 25px;
  font-weight: 600;
}
#downloads_div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#downloads_div li {
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 10px 10px;
  border-radius: 10px;
  padding-bottom: 0px;
  height: 190px;
}
.pdf_icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00cc99;
  margin: 0 auto 15px;
  border-radius: 50%;
}
#downloads_div a {
  text-align: center;
  padding: 10px 10px;
  padding-bottom: 0px;
}
#downloads_div a h4 {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.16px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #0f172a;
}
.down_icon {
  color: #787b84;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.14px;
  border-top: 1px solid #edf3f5;
  border-top-color: rgb(237, 243, 245);
  margin: 0 -10px;
  padding: 4px;
}
@media only screen and (max-width: 1400px) {
  #foto_and_text_universitets {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  #stars_universitets {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
@media only screen and (max-width: 1200px) {
  .boxes_choose {
    width: 100%;
  }
  /* #main_second_section aside {
    padding: 0px 15px 0px 15px;
  } */
  #text_foto_second_sec {
    width: 95%;
  }
}
@media only screen and (max-width: 1000px) {
  #main_second_section {
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    gap: 20px;
  }
  #main_second_section > section {
    width: 100%;
    /* padding: 0 25px; */
  }
  #img_girl {
    width: 50%;
    margin-top: 20px;
  }
  #img_girl img {
    width: 100%;
  }
  #foto_and_text_universitets {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  #stars_universitets {
    justify-content: center;
    flex-direction: column;
    gap: 15px;
  }
  #main_second_section aside {
    width: 100%;
    /* padding: 0px 15px 0px 15px; */
  }
  #first_section_text h1 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 780px) {
  #foto_and_text_universitets {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  #img_girl {
    width: 100%;
    margin-top: 20px;
  }
  .aside_second_sec {
    background-color: #edf3f5;
    padding: 20px 30px;
    width: 100%;
    border-radius: 20px;
  }
  #downloads_div a h4 {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.16px;
    margin-bottom: 15px;
    font-weight: 600;
    color: #0f172a;
  }
}

#specialities_section .container .table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  border-radius: 10px;
  /* border: 1px solid red; */
  /* border: 1px solid black; */
}
#specialities_section table {
  border-collapse: collapse;
  width: 100%;
  overflow-y: auto;
  white-space: nowrap;
}
#specialities_section table th {
  background-color: #fbce23;
  color: white;
}

#specialities_section table,
th,
td {
  /* border: 1px solid black; */
  /* border-radius: 10px; */
}
#specialities_section table th div span {
  padding: 10px;
  display: inline-block;
  text-align: center;
  width: 50%;
}
#specialities_section table th div p {
  padding: 10px;
  /* border-bottom: 1px solid black; */
}
#specialities_section table th:nth-child(1),
#specialities_section table td:nth-child(1) {
  padding: 10px;
}
#specialities_section table th div span,
#specialities_section table td div span {
  width: 50%;
  display: inline-block;
  text-align: center;
}
#specialities_section table th div span:nth-of-type(1),
#specialities_section table td div span:nth-of-type(1) {
  /* border-right: 1px solid black; */
}
#specialities_section table th div span,
#specialities_section table td div span {
  padding: 10px;
}
#specialities_section table th div span:nth-of-type(1)::after {
  content: "Əyani";
}
#specialities_section table th div span:nth-of-type(2)::after {
  content: "Qiyabi";
}
/* Mobil cihazlar için medya sorgusu */
@media (max-width: 600px) {
  #specialities_section table th div span:nth-of-type(1)::after {
    content: "Ə";
  }
  #specialities_section table th div span:nth-of-type(2)::after {
    content: "Q";
  }
}
#specialities_section table tr:nth-child(2n + 1) td {
  background-color: #edf3f5;
}
