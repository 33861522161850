#blogdetail_sec .container {
    /* position: relative; */
    padding-top: 50px;
    width: 100%;
}

/* #blogdetail_sec frame {
    width: 100%;
    border: 0;
    aspect-ratio: 16/9;
} */

#blogdetail_sec .title {
    font-size: 40px;
    padding-bottom: 20px;
}

#blogdetail_sec .blog_date {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin: 10px 0;
}
#blogdetail_sec .container h2+div{
    width: 100%!important;
    aspect-ratio: 16/9!important;
}